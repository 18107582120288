import nidhi from "../assets/images/Nidhi 1.png"
import batch from "../assets/images/ribbon-badge 1.png";
import truck from "../assets/images/truck.png"
import everest from "../assets/images/everest.png"
import ribon from "../assets/images/ribon.png";
import hand from "../assets/images/hand.png"
import girl from '../assets/images/stars.png'

const Teams = () => {
  return (
    <section id="team" className="container-fluid">

      <div className="parallax-obj1 hidden-sm hidden-xs team-para" data-0="opacity:1;"
        data-100="transform:translatey(40%);"
        data-center-center="transform:translatey(-60%);">
        <img src={girl} alt="toddler education programs" />
      </div>

      <div className="parallax-obj1 hidden-sm hidden-xs team-para2" data-0="opacity:1;"
        data-100="transform:translatey(40%);"
        data-center-center="transform:translatey(-60%);">
        <img src={truck} alt="toddler education programs" />
      </div>

      <div className="parallax-obj1 hidden-sm hidden-xs team-para3" data-0="opacity:1;"
        data-100="transform:translatey(40%);"
        data-center-center="transform:translatey(-60%);">
        <img src={everest} alt="toddler education programs" />
      </div>

      <div className="container">
        <div className="row">
          <div className="section-heading">
            <h2 className='position-relative text-light-blue'><span className='heading'></span>Owner and <span className='text-red'>Director</span></h2>
          </div>
          <div className="col-md-12 team-container">

            <div className="col-md-3">
              <img src={nidhi} alt="licensed daycare providers near Oak Harbor" className='w-100 phone-img' />
              <div className="parallax-obj2 hidden-sm hidden-xs team-para-1" data-0="opacity:1;"
                data-100="transform:translatey( 40%);"
                data-center-center="transform:translatey(-60%);">
                <img src={nidhi} alt="licensed daycare providers near Oak Harbor" />
              </div>
            </div>
            <div className="col-md-9">

              <div className="team-content-section">
                <div className="team-content">

                  <div className="team-content-header">
                    <h2 className='text-light-blue'>Nidhi Gupta</h2>
                    <div>
                      <img src={batch} alt="" />
                    </div>
                  </div>
                  <p className='text-light-blue'>Hi, I'm Nidhi Gupta, a happily married mother of two daughters. One is a computer science freshman at Berkeley, and the other attends TESLA STEM High School in Redmond, WA. Prior to owning Nurture with Care Kids Academy, I was a para-educator for over 8 years, split between Lake Washington School District (LWSD) in WA and Fremont Unified School District (FUSD) in CA, working with students from pre-K to 6th grade. I supervised and led groups of 6 to 10 students in various subjects, including PE and computer labs. In my free time, I enjoy shopping, cooking, and family time. I'm excited for our journey at Nurture with Care Kids Academy, aiming to make a bigger impact on our community.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="parallax-obj1 hidden-sm hidden-xs team-para4" data-0="opacity:1;"
          data-100="transform:translatey(40%);"
          data-center-center="transform:translatey(-60%);">
          <img src={ribon} alt="toddler education programs" />
        </div>

        <div className="parallax-obj1 hidden-sm hidden-xs team-para5" data-0="opacity:1;"
          data-100="transform:translatey(40%);"
          data-center-center="transform:translatey(-60%);">
          <img src={hand} alt="toddler education programs" />
        </div>
      </div>

    </section>
  )
}

export default Teams