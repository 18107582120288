
import boy1ornament from '../assets/images/about-left.webp'
import boy1ornament2 from '../assets/images/cloud2.png'
import mission from '../assets/images/Our mission 518x518.jpg'
import choose from '../assets/images/Why choose us 518x518.jpg'
import philosphy from '../assets/images/Our Philosophy 518x518.jpg'
import programs from '../assets/images/Facilities and Programs 518x518.jpg'
import welcomeback from "../assets/images/welcome-new.webp"
import layer from "../assets/images/layer-new.png"
import { useState } from 'react';

const About = () => {

  const iniTab = {
    tab1: '',
    tab2: '',
    tab3: '',
    tab4: '',
    tab5: '',
  }
  const getTabValue = (tab) => {
    const newTab = { ...iniTab }
    newTab[tab] = 'active in'

    return newTab
  }

  const [tab, setTab] = useState(getTabValue('tab1'))

  const onHandleTab = (tab) => {
    setTab(getTabValue(tab))
  }

  return (
    <div id="about" className="container-fluid">
      <div className="container layer-section">
        <div className="row">
          <div className="col-md-6">
            <div className="section-heading">
              <h1 className='heading' style={{ textShadow: "0px 2px 5px #0000009C" }}><span className='text-red'>Daycare </span> <span className='text-light-red'>in</span><br /><span className='text-blue'>Oak Harbor</span></h1>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <img src={layer} alt="layer" loading="lazy" />
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5 about-tab-section">
        <div className="row">
          <div className="parallax-obj2 hidden-sm hidden-xs about-para" data-0="opacity:1;"
            data-100="transform:translatey( 40%);"
            data-center-center="transform:translatey(-60%);">
            <img src={boy1ornament} alt="licensed daycare providers near Oak Harbor" loading="lazy" />
          </div>
          <div className="col-md-12 col-centered">
            <div className="tab" role="tabpanel">

              <ul className="nav nav-tabs mr-halfr">
                <li role="presentation" className={tab.tab1 + ""}>
                  <button className='btn-link text-light-blue' onClick={() => onHandleTab('tab1')}>Welcome</button>
                </li>
                <li role="presentation" className={tab.tab2 + ""}>
                  <button className='btn-link text-light-blue' onClick={() => onHandleTab('tab2')}>Our Mission</button>
                </li>
                <li role="presentation" className={tab.tab3 + ""}>
                  <button className='btn-link text-light-blue' onClick={() => onHandleTab('tab3')}>Why Choose Us</button>
                </li>
                <li role="presentation" className={tab.tab4 + ""}>
                  <button className='btn-link text-light-blue' onClick={() => onHandleTab('tab4')}>Our Philosophy</button>
                </li>
                <li role="presentation" className={tab.tab5 + ""}>
                  <button className='btn-link text-light-blue' onClick={() => onHandleTab('tab5')}>Facilities and Programs</button>
                </li>
              </ul>
              <div className="tab-content">

                <div role="tabpanel" className={`tab-pane fade col-lg-12 ${tab.tab1}`}>
                  <div className="col-lg-5">
                    <h3 className="text-blue position-relative"> <span className='heading'></span>Welcome</h3>
                    <p className='text-blue'>At Nurture with Care Kids Academy, we are more than just your childcare facility. We are a family that strives to see your child grow and be happy. Our academy is nestled in the center of Oak Harbor, where young toddlers and preschoolers feel safe and richly stimulated. We are the best and most trusted childcare near Oak Harbor, determined to foster a comfortable space that paves the way for each child to succeed in life. </p>
                  </div>
                  <div className="col-lg-7">
                    <img src={welcomeback} alt="young toddler activities in Oak Harbor" className="w-100 img-responsive" loading="lazy" />
                  </div>
                </div>

                <div role="tabpanel" className={`tab-pane fade col-lg-12 ${tab.tab2} d-flex justify-content-between`}>
                  <div className="col-lg-7 margin1">
                    <img src={mission} alt="young children's learning center in Oak Harbor" className="w-100 img-responsive" loading="lazy" />
                  </div>
                  <div className="panel-group col-lg-5 margin1">
                    <h3 className="text-blue position-relative"> <span className='heading'></span>Our Mission</h3>
                    <p className='text-blue'>Our mission is simple yet profound: to make children confident, curious, and equipped with essential life skills. As affordable childcare near Oak Harbor, we provide childcare help for working parents and the opportunity to take advantage of high-quality early education and care. We aspire to provide unique learning experiences to help direct minds and cultivate an enduring love for learning.</p>
                  </div>
                </div>
                <div role="tabpanel" className={`tab-pane fade col-lg-12 ${tab.tab3}`}>
                  <div className="panel-group col-lg-5 margin1" >
                    <h3 className="text-blue position-relative"> <span className='heading'></span>Why Choose Us</h3>
                    <p className="text-blue">Nurture with Care Kids Academy tops the list if you are looking for a trusted daycare. Here's why families trust us:</p>
                    <ul className="custom no-margin text-normal text-blue">
                      <li><strong>Tailored Programs:</strong> Our curriculum is designed to foster the growth and creativity of young toddlers, toddlers, and preschoolers. </li>
                      <li><strong>Working Parent Support:</strong> We help make working parents' lives manageable with childcare help.</li>
                      <li><strong>Qualified Educators:</strong> We have a passionate and experienced staff committed to educating, motivating, and nurturing each child's potential.</li>
                      <li><strong>Affordable Care:</strong> Great education should not come at a great cost, and that is why our care is superb at an affordable price.</li>
                    </ul>
                  </div>

                  <div className="col-lg-7 margin1">
                    <img src={choose} alt="young toddler activities for daycare in Oak Harbor" className="img-curved center-block img-responsive" loading="lazy" />
                  </div>
                </div>

                <div role="tabpanel" className={`tab-pane fade col-lg-12 ${tab.tab4}`}>
                  <div className="col-lg-5 margin1">
                    <img src={philosphy} alt="early childhood education wa" className="w-100 img-responsive" loading="lazy" />
                  </div>

                  <div className="panel-group col-lg-7 margin1">
                    <h3 className="text-blue position-relative"> <span className='heading'></span>Our Philosophy</h3>
                    <p className='text-blue'>We believe every child is special and can achieve greatness at Nurture with Care Kids Academy. Our philosophy is based on best practices for building a foundation of trust, love, and respect while doing popular toddler activities and exploring preschool and young toddler activities. We encourage children to explore their surroundings, think critically, and form emotionally and socially strong bonds.</p>

                  </div>
                </div>

                <div role="tabpanel" className={`tab-pane fade col-lg-12 ${tab.tab5}`}>
                  <div className="panel-group col-lg-5 margin1" >
                    <h3 className="text-blue position-relative"> <span className='heading'></span>Facilities and Programs</h3>
                    <p className='text-blue'>Our state-of-the-art facilities and thoughtfully designed programs cater to young toddlers, toddlers, and preschoolers, ensuring a stimulating and safe environment for every stage of development:</p>
                    <ul className="custom no-margin text-normal text-blue">
                      <li><strong>Young Toddlers (12-18 months):</strong> Engaging activities for young toddlers involve sensory play, early motor skills, and early socialization. </li>
                      <li><strong>Toddlers (18-36 months):</strong> It is a structured yet playful activity that helps improve communication and create and solve problems early.</li>
                      <li><strong>Preschoolers (3+ years): </strong>As a leading preschool learning center, we prepare children for kindergarten with a literacy, numeracy, and self-confidence-based curriculum.</li>
                    </ul>
                    <p className='text-blue'>Whether you want to spend quality time at creative art corners or take your child outside for some play, our academy is ready to offer support along your child’s journey of discovery and exploration.</p>
                  </div>

                  <div className="col-lg-7 margin1">
                    <img src={programs} alt="day care activities for toddlers near Oak Harbor" className="w-100 img-responsive" loading="lazy" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="parallax-obj2 hidden-sm hidden-xs about-para2" data-0="opacity:1;"
            data-100="transform:translatey( 40%);"
            data-center-center="transform:translatey(-60%);">
            <img src={boy1ornament2} alt="licensed daycare providers near Oak Harbor" loading="lazy" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About