import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { HashLink as Link } from 'react-router-hash-link';
import Layout from '../components/layout';
import ScrollToTop from '../assets/js/Scrolltotop';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../assets/css/blog.css';
import { blogs } from '../meta/blogs';
import Calender from "../assets/blog-img/calendar.png"
import Like from "../assets/blog-img/like.png"
import Facebook from "../assets/blog-img/facebook.png"
import Instagram from "../assets/blog-img/instagram.png"


const scrollWithOffset = (el, offset) => {
    const elementPosition = el.getBoundingClientRect().top + window.pageYOffset - offset;
    window.scroll({
        top: elementPosition,
    });
};

const extractHeadings = (content) => {
    const regex = /<h([2-6]) id="([^"]+)">(.*?)<\/h\1>/g;
    const headings = [];
    let match;

    while ((match = regex.exec(content)) !== null) {
        const level = match[1];
        const id = match[2];
        const text = match[3];

        headings.push({ id, text, level });
    }
    return headings;
};

const BlogDetail = () => {

    const { slug } = useParams();

    const blog = blogs.find(b => b.slug === slug);

    if (!blog) {
        return <p>Blog not found!</p>;
    }

    const filteredBlogs = blogs.filter(b => b.slug !== slug);

    const headings = extractHeadings(blog.content);

    const blogUrl = `https://nurturewithcarekidsacademy.com/blog-detail/${slug}`;

    return (
        <>
            <ScrollToTop />
            <div id="page-section">
                <Layout title={blog.meta_title} description={blog.meta_description}>
                    <Helmet>
                        <meta property="og:site_name" content="Nurture With Care Kids Academy" />
                        <meta property="og:url" content={`https://nurturewithcarekidsacademy.com/blog-detail/${slug}`} />
                        <meta property="og:title" content={blog.meta_title} />
                        <meta property="og:type" content="article" />
                        <meta property="og:description" content={blog.meta_description} />
                        <meta property="og:image" content={blog.thumbnail} />
                    </Helmet>
                    <section id="blog" className='blog-detail-content' style={{ paddingTop: "0" }}>
                        <div className="container-fluid">
                            <div className="jumbo-heading">
                                <div className="container">
                                    <h1>{blog.title}</h1>
                                </div>
                            </div>

                            <div className="blog-details">
                                <div className="container mt-5">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="hero">
                                                <img className="img-fluid" src={blog.banner} alt="Hero Image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-8 blog-details-text">
                                            <div className="blog-admin">
                                                <div className="img-box">
                                                    <img className="img-fluid" src={Calender} alt="calendar icon" />
                                                </div>
                                                <div>
                                                    <span className="date">{blog.date}</span> | <span className="author">By {blog.author}</span>
                                                </div>
                                            </div>
                                            <div dangerouslySetInnerHTML={{ __html: blog.content }} />
                                        </div>

                                        <div className="col-sm-4 blog-sidebar">
                                            <div className="social-icon">
                                                <h2 className="social-icon-heading">Like & Share</h2>
                                                <ul className="social-icon-ul">
                                                    <li><a href="#"><img className="img-fluid" src={Like} alt="social icon" /></a></li>
                                                    {/* <li><a href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(blogUrl)}`} target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Like} alt="social icon" /></a></li> */}
                                                    <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(blogUrl)}`} target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Facebook} alt="social icon" /></a></li>
                                                    <li><a href={`https://www.instagram.com/?url=${encodeURIComponent(blogUrl)}`} target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Instagram} alt="social icon" /></a></li>
                                                </ul>
                                            </div>
                                            <div className="sidebar-heading">
                                                <h4 className="sidebar-heading-heading">Headings</h4>
                                                <ul className="list-unstyled">
                                                    {headings.map((heading, index) => (
                                                        <li key={index}>
                                                            <Link
                                                                to={`#heading_${index + 1}`}
                                                                smooth
                                                                scroll={el => scrollWithOffset(el, 100)}
                                                            >
                                                                {heading.text}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Recent Blogs Section */}
                                    {filteredBlogs.length > 0 && (
                                        <div className="recent-blogs mt-5">
                                            <h3 className="text-center mb-4 recent-blogs-heading">Recent Blogs</h3>
                                            <OwlCarousel
                                                className="owl-theme"
                                                loop
                                                nav={false}
                                                dots={true}
                                                items={3}
                                                autoplay={true}
                                                autoplayTimeout={4000}
                                                responsive={{
                                                    0: {
                                                        items: 1
                                                    },
                                                    600: {
                                                        items: 1,
                                                    },
                                                }}
                                            >
                                                {filteredBlogs.map((blog) => (
                                                    <div key={blog.id} className="items">
                                                        <div className="card blog-card">
                                                            <div className="img-box">
                                                                <Link to={`/blog/${blog.slug}`}>
                                                                    <img className="img-fluid" src={blog.image} alt={blog.title} />
                                                                </Link>
                                                            </div>
                                                            <div className="card-body">
                                                                <h5 className="blog-heading">
                                                                    <Link to={`/blog/${blog.slug}`}>{blog.title}</Link>
                                                                </h5>
                                                                <p className="blog-des">{blog.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </OwlCarousel>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </Layout>
            </div>
        </>
    );
};

export default BlogDetail;
