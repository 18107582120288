import React from "react";

import chamber from "../assets/images/chamber.png";
import mccyn from "../assets/images/mccyn.png";
import dshs from "../assets/images/dshs.png";


const partnerships = [
    {
        logo: chamber,
        description:
            "Proud member of the Oak Harbor Chamber of Commerce, committed to fostering community growth and collaboration.",
        link: "https://www.oakharborchamber.com/ "
    },
    {
        logo: mccyn,
        description:
            "Approved provider of MCCYN Military Child Care in Your Neighborhood program, supporting military families with childcare needs.",
        link: "https://public.militarychildcare.csd.disa.mil/mcc-central/mcchome/mccyn"
    },
    {
        logo: dshs,
        description:
            "Approved provider for DSHS (Washington Department of Social and Health Services) childcare subsidies.",
        link: "https://www.dshs.wa.gov/"
    }
];

const Partners = () => {
    return (
        <div className="container text-center partners-container">
            <div className="section-heading">
                <h2><span className='heading'>Partnership</span></h2>
            </div>
            <div className="row partners-row">
                {partnerships.map((partner, index) => (
                    <div key={index} className="col-md-4 col-sm-12">
                        <a href={partner.link} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                            <div className="card shadow border-0 h-100">
                                <img src={partner.logo} alt="partner" className="partners-logo" />
                                <div className="card-body">
                                    <p className="card-text">{partner.description}</p>
                                </div>
                            </div>
                        </a> 
                    </div>
                ))}
            </div>   
        </div>
    );
};

export default Partners;