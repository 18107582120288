import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import ScrollToTop from '../assets/js/Scrolltotop';
import '../assets/css/blog.css';
import { blogs } from '../meta/blogs';
import Calender from "../assets/blog-img/calendar.png"
import Search from "../assets/blog-img/search-icon.png"
import left from "../assets/images/left.png";
import right from "../assets/images/right.png";
import boy1ornament from '../assets/images/about-left.webp';
import kutta from "../assets/images/kutta.png"

const Blog = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredBlogs, setFilteredBlogs] = useState(blogs);

    const blogsPerPage = 4;

    const filterBlogs = (term) => {
        if (term === "") {
            setFilteredBlogs(blogs);
        } else {
            const results = blogs.filter((blog) =>
                blog.title.toLowerCase().includes(term.toLowerCase()) ||
                blog.description.toLowerCase().includes(term.toLowerCase())
            );
            setFilteredBlogs(results);
        }
    };

    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const totalPages = Math.ceil(filteredBlogs.length / blogsPerPage);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        filterBlogs(searchTerm);
        setCurrentPage(1);
    };

    return (
        <>
            <ScrollToTop />
            <div id="page-section">
                <Layout title="Blogs | Nurture with Care Kids Academy" description="Explore expert insights on childcare, early learning, and parenting tips at Nurture with Care Kids Academy. Stay informed with our latest blogs on child development and education in Oak Harbor.">
                    <Helmet>
                        <meta property="og:site_name" content="Nurture With Care Kids Academy" />
                        <meta property="og:url" content="https://nurturewithcarekidsacademy.com/blogs" />
                        <meta property="og:title" content="Blogs" />
                        <meta property="og:type" content="article" />
                        <meta property="og:description" content="Explore expert insights on childcare, early learning, and parenting tips at Nurture with Care Kids Academy. Stay informed with our latest blogs on child development and education in Oak Harbor." />
                        <meta property="og:image" content="https://nurturewithcarekidsacademy.com/static/media/logo-nurture.fe8ff81585c61f3e8f0c.png" />
                    </Helmet>
                    <section id="blog" style={{ paddingTop: "0" }}>
                        <div className="container-fluid">
                            <div className="jumbo-heading">
                                <h1>Our Blogs</h1>
                            </div>


                            <div className="blog-listing">
                                <div className="container">
                                    <div className="row">

                                        <div className="col-md-12">
                                            <div className="search-bar">
                                                <h5>Search Blogs</h5>
                                                <div className="input-box">
                                                    <form onSubmit={handleSearchSubmit}>
                                                        <input
                                                            type="text"
                                                            placeholder="Search"
                                                            value={searchTerm}
                                                            onChange={handleSearchChange}
                                                        />
                                                        <button type="submit">
                                                            <img className="img-fluid search-icon" src={Search} alt="search icon" />
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Check if no blogs found and show message */}
                                    {filteredBlogs.length === 0 ? (
                                        <div className="no-results">
                                            <p>No blogs found for your search.</p>
                                        </div>
                                    ) : (
                                        <div className="row">
                                            <div className="parallax-obj2 hidden-sm hidden-xs about-para" data-0="opacity:1;"
                                                data-100="transform:translatey( 40%);"
                                                data-center-center="transform:translatey(-60%);">
                                                <img src={boy1ornament} alt="licensed daycare providers near Oak Harbor" loading="lazy" />
                                            </div>
                                            {currentBlogs.map(blog => (
                                                <div key={blog.id} className="col-sm-6">
                                                    <div className="blog-card">
                                                        <div className="img-box">
                                                            <img className="img-fluid" src={blog.thumbnail} alt="Blog Image" />
                                                            <p className="date">
                                                                <img className="img-fluid" src={Calender} alt="calendar icon" />
                                                                <span>{blog.date}</span>
                                                            </p>
                                                        </div>
                                                        <div className="blog-content">
                                                        <Link to={`/blog-detail/${blog.slug}`}><h3>{blog.title}</h3></Link>
                                                            <p>{blog.description.substring(0, 150)}...</p>
                                                            <Link to={`/blog-detail/${blog.slug}`} className="read-more">Read More</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    )}

                                    {/* Pagination */}
                                    <div className="row">

                                        <div className="col-md-12">
                                            <div className="pagination">
                                                {currentPage > 1 && (
                                                    <a className="arrow" onClick={() => paginate(currentPage - 1)}>
                                                        <img className="img-fluid" src={left} alt="" />
                                                    </a>
                                                )}
                                                {[...Array(totalPages)].map((_, index) => (
                                                    <a
                                                        key={index}
                                                        href="javascript:void(0)"
                                                        className={currentPage === index + 1 ? 'active' : ''}
                                                        onClick={() => paginate(index + 1)}
                                                    >
                                                        {index + 1}
                                                    </a>
                                                ))}
                                                {currentPage < totalPages && (
                                                    <a className="arrow" onClick={() => paginate(currentPage + 1)}>
                                                        <img className="img-fluid" src={right} alt="" />
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Layout>
            </div>
        </>
    );
}

export default Blog;
