import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import ScrollToTop from '../assets/js/Scrolltotop'
import React, { useEffect } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import NotFound from './NotFound';

import oct23 from '../meta/October 2023 Newsletter NWCKA.pdf#pagemode=none'
import nov23 from '../meta/November 2023 Newsletter NWCKA.pdf#pagemode=none'
import dec23 from '../meta/December 2023 Newsletter NWCKA.pdf#pagemode=none';
import jan24 from '../meta/January 2024 Newsletter NWCKA Oak Harbor.pdf#pagemode=none';
import feb24 from '../meta/February 2024 Newsletter NWCKA.pdf#pagemode=none';
import mar24 from '../meta/Nurture with Care Newsletter  Mar 2024.pdf#pagemode=none';
import apr24 from '../meta/Nurture with Care Newsletter  Apr 2024.pdf#pagemode=none';
import may24 from '../meta/Nurture with Care Newsletter  May 2024_compressed.pdf#pagemode=none';
import jun24 from '../meta/Nurture with Care Newsletter June 2024 (1)_compressed.pdf#pagemode=none';
import jul24 from '../meta/Nurture with Care Newsletter July 2024_compressed.pdf#pagemode=none';
import aug24 from '../meta/Nurture with Care Newsletter  Aug 2024_compressed.pdf#pagemode=none';
import sep24 from '../meta/Nurture with Care Newsletter  September 2024_compressed.pdf#pagemode=none';
import oct24 from '../meta/Nurture with Care Newsletter  October 2024_compressed.pdf#pagemode=none';
import nov24 from '../meta/NurturewithCareNewsletterNovember2024_compressed.pdf#pagemode=none';
import dec24 from '../meta/Nurture_with_Care_Newsletter_December_2024.pdf#pagemode=none';
import jan25 from '../meta/Nurture_with_Care_Newsletter_January_2025.pdf#pagemode=none';
import feb25 from '../meta/Nurture_with_Care_Newsletter _February_2025_compressed.pdf#pagemode=none';
import mar25 from '../meta/Nurture_with_Care_Newsletter_March_2025.pdf#pagemode=none';
import apr25 from '../meta/Nurture_with_Care_Newsletter_Apr_2025_compressed.pdf#pagemode=none';




const Contact = () => {
  const params = useParams();

  useEffect(() => {
    const handleLinkClick = (e) => {
      const link = e.target.closest(
        ".rpv-core__annotation.rpv-core__annotation--link"
      );
      if (link && link.href) {
        window.open(link.href, "_blank");
      }
    };

    const viewerContainer = document.querySelector(".rpv-core__viewer");
    if (viewerContainer) {
      viewerContainer.addEventListener("click", handleLinkClick);
    }

    return () => {
      if (viewerContainer) {
        viewerContainer.removeEventListener("click", handleLinkClick);
      }
    };
  }, []);

  let newsletter = '';
  let metaTitle = '';
  let metaDescription = '';

  const validSlugs = [
    'apr25','mar25','feb25','jan25','dec24','nov24','oct24', 'sep24', 'aug24', 'jul24', 'jun24',
    'may24', 'apr24', 'mar24', 'feb24', 'jan24',
    'dec23', 'nov23', 'oct23'
  ];

  if (!validSlugs.includes(params.nlMonth)) {
    return <NotFound />; 
  }

  switch (params.nlMonth) {
    case 'apr25':
      newsletter = apr25;
      metaTitle = "April 2025 Newsletter: Holi Festival, Spring Fun & Staff Spotlight | Nurture with Care Kids Academy";
      metaDescription = "This month at Nurture with Care Kids Academy, we’re celebrating Holi on March 14 and St. Patrick’s Day on March 17! Kids will learn about spring, weather changes, and sensory exploration. Stay updated with center info and birthdays!";
      break;
    case 'mar25':
      newsletter = mar25;
      metaTitle = "March 2025 Newsletter: Holi Festival, Spring Fun & Staff Spotlight | Nurture with Care Kids Academy";
      metaDescription = "This month at Nurture with Care Kids Academy, we’re celebrating Holi on March 14 and St. Patrick’s Day on March 17! Kids will learn about spring, weather changes, and sensory exploration. Stay updated with center info and birthdays!";
      break;
    case 'feb25':
      newsletter = feb25;
      metaTitle = "February 2025 Newsletter: Valentine’s Day Celebration, Fun Learning & Staff Spotlight | Nurture with Care Kids Academy";
      metaDescription = "This month at Nurture with Care Kids Academy, we’re celebrating Valentine’s Day with a special event on February 14 at 3 PM! Kids will learn about feelings, healthy habits, and the rainforest. Stay updated with important center information and birthdays!";
      break;
    case 'jan25':
      newsletter = jan25;
      metaTitle = "January 2025 Newsletter: New Year Celebrations, Santa Visit & Exciting Updates | Nurture with Care Kids Academy";
      metaDescription = "Happy New Year from Nurture with Care Kids Academy! This month, we welcomed 8 new students and 3 new staff members. We also had a fun-filled holiday party with a Santa visit and goodie bags! Check out our staff spotlight, upcoming events, and important center updates.";
      break;
    case 'dec24':
      newsletter = dec24;
      metaTitle = "December 2024 Newsletter: Holiday Party, Santa Visit & Space Adventures | Nurture with Care Kids Academy";
      metaDescription = "Celebrate the season with our December highlights! Join us for the holiday party and a special visit from Santa on December 20. Explore fun themes like the Sun, Moon, and Stars, snow animals, and life in space.";
      break;
    case 'nov24':
      newsletter = nov24;
      metaTitle = "November 2024 Newsletter: Imagination Theme, Diwali Celebration & Key Dates | Nurture with Care Kids Academy";
      metaDescription = "Dive into November's theme of 'My Imagination and Helpful Habits', exploring superheroes, fairies, and healthy habits. Celebrate Diwali with us and mark important dates, including Veteran's Day and Thanksgiving closures. Learn about our Oak Harbor location, staff highlights, and resources for child development.";
      break;
    case 'oct24':
      newsletter = oct24;
      metaTitle = "October 2024 Newsletter: Dino Theme, Harvest Fun & Important Dates | Nurture with Care Kids Academy";
      metaDescription = "Discover the exciting Dino and Happy Harvesting theme for October 2024 at Nurture with Care Kids Academy. Learn about autumn activities, syllables, and synonyms, along with updates on center closures, festivals, and a special Halloween parade.";
      break;
    case 'sep24':
      newsletter = sep24;
      metaTitle = "September 2024 Newsletter: Curriculum Theme, Key Updates & Parent Resources | Nurture with Care Kids Academy";
      metaDescription = "Explore the 'All About Me' curriculum for September 2024 at Nurture with Care Kids Academy. Get important updates on back-to-school reminders, Labor Day, and tips on managing toddler behavior, teaching financial literacy, and more​.";
      break;
    case 'aug24':
      newsletter = aug24;
      metaTitle = " August 2024 Newsletter: Curriculum Updates, Graduation Highlights & Parent Resources | Nurture with Care Kids Academy";
      metaDescription = "August 2024 at Nurture with Care Kids Academy offers exciting curriculum updates, graduation ceremony details, and valuable parent resources. Stay informed on important events and childcare subsidy information.";
      break;
    case 'jul24':
      newsletter = jul24;
      metaTitle = "July 2024 Newsletter: Curriculum Highlights, Important Info & Parent Tips | Nurture with Care Kids Academy";
      metaDescription = "July 2024 brings exciting curriculum highlights, essential updates, and introductions at our preschool. Meet our program supervisor, get important information on holidays and health policies, and find tips for summer safety and childcare subsidies.";
      break;
    case 'jun24':
      newsletter = jun24;
      metaTitle = "June 2024 Newsletter: Childcare Updates, Father's Day Highlights, and Parenting Tips";
      metaDescription = "Stay informed with our June newsletter! Learn about childcare subsidies, center hours, high school internships, & tips for promoting your child's health and development. Celebrate Father's Day & check out June birthdays too!";
      break;
    case 'may24':
      newsletter = may24;
      metaTitle = "May 2024 Newsletter:  Exploring Flowers, Animals, Yoga, and More | Nurture with Care Kids Academy";
      metaDescription = "Discover what's blooming this May 2024! From immersive learning about flowers, animals, and Yoga poses to celebrating Mother's Day with 'Muffins with Moms,' our newsletter is packed with exciting updates.";
      break;
    case 'apr24':
      newsletter = apr24;
      metaTitle = "April 2024 Newsletter: Exploring Plants, Butterflies, and Springtime Fun | Nurture With Care Kids Academy";
      metaDescription = "Dive into topics like plant biology, butterflies, and springtime activities in Mount Vernon. Plus, check the useful parenting tips, inspiring quotes, and intriguing fun facts for all ages.";
      break;

    case 'mar24':
      newsletter = mar24;
      metaTitle = "March 2024 Newsletter: St. Patrick's Day, Holi Celebration, and Staff Spotlight | Nurture With Care Kids Academy";
      metaDescription = "Don't miss out on the latest March updates at our daycare in oak harbor! Join us in making special moments for our staff and students while exploring fun facts and educational resources.";
      break;

    case 'feb24':
      newsletter = feb24;
      metaTitle = "February 2024 Newsletter: Petting Zoo, Valentine's Day, and Staff Spotlight | Nurture With Care Kids Academy ";
      metaDescription = "Exciting events include a petting zoo, a Valentine's Day celebration, and a spotlight on Cora Tejano. Stay updated on birthdays, childcare info, and the latest highlights at our growing center!";
      break;

    case 'jan24':
      newsletter = jan24;
      metaTitle = "January 2024 Newsletter: Happy New Year & Exciting Updates | Nurture With Care Kids Academy";
      metaDescription = "Join us for another fantastic year ahead! Check upcoming events like musical night with families, and birthdays, and stay informed about childcare subsidies, and recent highlights, including Santa's visit!";
      break;

    case 'dec23':
      newsletter = dec23;
      metaTitle = "December 2023 Newsletter: Holiday Cheer & Celebrations | Nurture With Care Kids Academy";
      metaDescription = "Join us for festive events like kids' photos with Santa, a Christmas celebration with dinner, and a staff spotlight on Erin Hedrick. Plus, mark your calendar for holiday closures and birthdays!";
      break;

    case 'nov23':
      newsletter = nov23;
      metaTitle = "November 2023 Newsletter: Updates & Veteran’s Gratitude | Nurture With Care Kids Academy";
      metaDescription = "Stay updated on closures and events while enjoying themes of scarecrows, turkeys, Veterans Day, and Thanksgiving. Remembering our service members and giving thanks for all we have.";
      break;

    case 'oct23':
      newsletter = oct23;
      metaTitle = "October 2023 Newsletter: Updates, Reminders & Important Dates | Nurture With Care Kids Academy";
      metaDescription = "Stay informed with our fall newsletter! From the director's message to important dates and reminders. Don't miss out on upcoming events at the best childcare near Oak Harbor.";
      break;

    default:
      newsletter = oct23;
      metaTitle = "October 2023 Newsletter: Updates, Reminders & Important Dates | Nurture With Care Kids Academy";
      metaDescription = "Stay informed with our fall newsletter! From the director's message to important dates and reminders. Don't miss out on upcoming events at the best childcare near Oak Harbor.";
      break;
  }

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const onLoad = ({ getDocument }) => {
    const viewerContainer = document.querySelector(".rpv-core__viewer");
    if (!viewerContainer) return;

    getDocument().promise.then((pdf) => {
      for (let i = 1; i <= pdf.numPages; i++) {
        pdf.getPage(i).then((page) => {
          page.getAnnotations().then((annotations) => {
            annotations.forEach((annotation) => {
              if (annotation.subtype === "Link") {
                const rect = annotation.rect;
                const linkElement = document.createElement("a");
                linkElement.classList.add(
                  "rpv-core__annotation",
                  "rpv-core__annotation--link"
                );
                linkElement.href = annotation.url || "#";
                linkElement.target = "_blank";
                linkElement.style.position = "absolute";
                linkElement.style.left = `${rect[0]}px`;
                linkElement.style.top = `${rect[1]}px`;
                linkElement.style.width = `${rect[2] - rect[0]}px`;
                linkElement.style.height = `${rect[3] - rect[1]}px`;
                viewerContainer.appendChild(linkElement);
              }
            });
          });
        });
      }
    });
  };

  return (
    <div id="page-section">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <ScrollToTop />
      <section id="blog" style={{ paddingTop: "0" }}>
        <div className="container-fluid">
          <div className="jumbo-heading">
            <h1 className='mb-2r'>Newsletters</h1>
            {/* <div className="hr contact-hr text-light"></div> */}
          </div>
          <div className="container">
            <Worker workerUrl={pdfjsWorker}>
              <div className='p-view'>
                <Viewer
                  fileUrl={newsletter}
                  plugins={[defaultLayoutPluginInstance]}
                  onLoad={onLoad}
                  theme={{ theme: 'dark' }}
                />
              </div>
            </Worker>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Contact