import Accordion from 'react-bootstrap/Accordion';

import yt from '../assets/images/services/young-toddlers-program1.jpg'
import yt2 from '../assets/images/services/young-program2.jpg'
import young from '../assets/images/services/individualized-care.svg'
import safe from '../assets/images/services/safe-exploration.svg'
import sensory from '../assets/images/services/sensory-activities.svg'
import social from '../assets/images/services/social-interaction.svg'
import communication from '../assets/images/services/communication.svg'
import Layout from '../components/layout';
import ScrollToTop from '../assets/js/Scrolltotop'

import hand from "../assets/images/hand.png"
import star from '../assets/images/stars.png'

import girl from "../assets/images/sleep-girl.png"
import kutta from "../assets/images/kutta.png"
import { Helmet } from 'react-helmet'

const Youngt = () => {
    return (
        <>
            <ScrollToTop />
            <div id="page-section">
                <Layout title="Young Toddlers Program in Oak Harbor | Nurture with Care Kids Academy" description="Our young toddlers program provides a safe and stimulating space for your child to learn, grow, and thrive. Enroll your little one today for early childhood development programs.">
                    <Helmet>
                        <meta property="og:site_name" content="Nurture With Care Kids Academy" />
                        <meta property="og:url" content="https://nurturewithcarekidsacademy.com/programs/young-toddlers-in-oak-harbor" />
                        <meta property="og:title" content="Young Toddlers Program in Oak Harbor" />
                        <meta property="og:type" content="article" />
                        <meta property="og:description" content="Our young toddlers program provides a safe and stimulating space for your child to learn, grow, and thrive. Enroll your little one today for early childhood development programs." />
                        <meta property="og:image" content="https://nurturewithcarekidsacademy.com/static/media/young-todler.053059de7e5ea92a5ff5.jpg" />
                        <script type="application/ld+json">
                            {JSON.stringify(
                                {
                                    "@context": "https://schema.org",
                                    "@type": "FAQPage",
                                    "mainEntity": [{
                                        "@type": "Question",
                                        "name": "Which culture operates to influence the early childhood program?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Nurture with Care Kids Academy, a top Early Childhood Learning Center, is influenced by multicultural values that embrace diversity and inclusiveness to enhance Early Childhood Education in WA. Curriculum development must be guided by cultural sensitivity to ensure interactions, celebrations, and a respectful environment for all children."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "What to consider when consulting an early childhood education program?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Your child's age, interests, and requirements should be considered. You must examine the program's staff qualifications, curriculum details, and class size. You just look for daycare programs for toddlers near Oak Harbor that emphasize parent engagement and collaboration."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "What are the three most important elements that an early childhood program must have?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "An engaging learning environment, competent and caring teachers, and an inclusive curriculum are three important elements of any early childhood program. A productive ambiance encourages discovery and inventiveness through individual tutors who offer the best childcare near Oak Harbor and attention. A comprehensive syllabus combines play-based instruction, social-emotional development, and basic academics, leading to all-around growth among young learners."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "What is your child-to-teacher ratio?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Nurture With Care Kids Academy - the best young children's learning center emphasizes personalized attention through varied child-teacher ratios based on age. They are as follows: 1:6 teacher-child ratio for infants aged 12 -21 months, 1:7 for toddlers aged 21 – 29 months, and 1:10 ratio for preschoolers aged between two and a half and five years."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "What qualifications do your young toddler's teachers hold?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Passionate about their jobs, our young toddler teachers are also competent caregivers. They meet all state qualifications, which include a high school diploma, early learning preschool qualifications, and specialized training in early childhood education (ECE). They offer the best early childhood development programs and young toddler activities for daycare. Hence, they have the required background knowledge to facilitate your baby's social, emotional, and cognitive development during this critical stage."
                                        }
                                    }]
                                }
                            )}</script>
                    </Helmet>

                    <section className="services" style={{ paddingTop: "0" }}>
                        <div className="container-fluid">
                            <div className="jumbo-heading">
                                <h1>Young Toddlers Program in Oak Harbor</h1>
                            </div>
                            <div className="parallax-obj1 hidden-sm hidden-xs service-para1 service-p" data-0="opacity:1;"
                                data-100="transform:translatey(40%);"
                                data-center-center="transform:translatey(-60%);">
                                <img src={kutta} alt="toddler education programs" />
                            </div>
                            <div className="container ">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4>Our Early Childhood Learning Center Nurturing Young Minds</h4>
                                        <p>Our goal is to create a nurturing and personalized space at our young children's learning center so that young toddlers can grow, learn, and thrive. Our dedicated and thoughtfully well-designed early childhood development programs recognize the importance of early childhood education and offer programs for every child based on their requirements.
                                        </p>
                                        <p>Our early childhood education experience as educators means we understand the critical importance of attention to students as one of the key factors for development. A well-defined child-to-staff ratio ensures that all children feel secure, valued, and supported as they embark on their learning journey.
                                        </p>


                                    </div>
                                    <div className="col-md-6">
                                        <img src={yt} alt="young toddler activities" className="img-curved  center-block img-responsive" />
                                    </div>
                                </div>
                                <div className="row mt-30">
                                    <div className="col-md-6">
                                        <img src={yt2} alt="young toddler activities" className="img-curved  center-block img-responsive" />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <h4>Young Toddler Activities that Engage Holistically</h4>
                                        <p>
                                        Young toddlers learn best from exploration and play. Our programs combine fun with developmental benefits in a variety of activities for young toddlers. From sensory-rich experiences to creative exercise, these activities encourage curiosity, improve motor skills, and permit imagination.
                                        </p>
                                        <p>
                                        In addition to this, young toddler learning activities at our center also include supervised group play, which helps children learn social interaction and empathy. With these gentle interactions, toddlers develop crucial communication and social skills and build a community and friendship.

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="parallax-obj1 hidden-sm hidden-xs service-para2 service-p" data-0="opacity:1;"
                                data-100="transform:translatey(40%);"
                                data-center-center="transform:translatey(-60%);">
                                <img src={girl} alt="toddler education programs" />
                            </div>
                        </div>
                    </section>

                    <section className="facilities bg-color4">
                        <div className="parallax-obj1 hidden-sm hidden-xs team-para service-p" data-0="opacity:1;"
                            data-100="transform:translatey(40%);"
                            data-center-center="transform:translatey(-60%);">
                            <img src={star} alt="toddler education programs" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div className="section-heading">
                                        <h2><span className='heading'></span>Facilities for <span className='text-red'>Young Toddlers</span></h2>
                                    </div>
                                    <p>Our preschool is focused on early learning, keeping it engaging and interesting for toddlers. We focus on age-appropriate activities to enhance their socialization skills, cognitive abilities, creativity, emotional development, and more. Nurture with Care Kids Academy wants our toddlers to grow academically, but we ensure they have opportunities to explore and have fun during their childhood. The academy believes in creating a nurturing and balanced atmosphere tailored for the little ones and their developmental phases, facilitating learning through play and exploration. We make the child's potential unfold by offering key facilities mentioned below:
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={safe} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Safe and Stimulating Exploration</h3>
                                        <p>Our safe and interactive early childhood learning center will give your toddler the opportunity to explore their surroundings. Designed to encourage active play and cognitive development, we make families with preschool children who are looking for the best preschool in Oak Harbor.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={sensory} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Sensory-Rich Activities</h3>
                                        <p> Young toddlers love exploring their senses. Our program combines sensory activities that stimulate creativity, improve motor skills, and increase curiosity, creating a solid foundation for a love of learning that can continue throughout a lifetime.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={social } className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Building Social Skills</h3>
                                        <p>With gentle interactions and group play, we help toddlers learn empathy, cooperate, and be part of a community. Our work has given us an approach that allows children to feel comfortable, a proposition sought by families seeking the best young toddlers’ activities.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={communication} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Daily Parent Communication</h3>
                                        <p>Our philosophy is that we build strong partnerships with parents. Every day, we update you on your child’s activities, meals, naps, photos, and more, so you are always in the loop about how your child is progressing and thriving.</p>
                                    </div>
                                </div>
                                {/* <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={communication} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Daily Communication</h3>
                                        <p> We maintain open lines of communication with parents/guardians to keep you informed about your child's day, including meals, naps, and activities.</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="parallax-obj1 hidden-sm hidden-xs team-para5 inner-service-para service-p" data-0="opacity:1;"
                            data-100="transform:translatey(40%);"
                            data-center-center="transform:translatey(-60%);">
                            <img src={hand} alt="toddler education programs" />
                        </div>
                    </section>

                    <section>
                        <div className='container'>
                            <div className="row">
                                <div className="section-heading">
                                    <h2><span className='heading'></span>FAQ'S</h2>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: "20px" }}>
                                <div className="col-md-12">
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>1. Which culture operates to influence the early childhood program?</Accordion.Header>
                                            <Accordion.Body>
                                                Nurture with Care Kids Academy, a top Early Childhood Learning Center, is influenced by multicultural values that embrace diversity and inclusiveness to enhance Early Childhood Education in WA. Curriculum development must be guided by cultural sensitivity to ensure interactions, celebrations, and a respectful environment for all children.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>2. What to consider when consulting an early childhood education program?</Accordion.Header>
                                            <Accordion.Body>
                                                Your child's age, interests, and requirements should be considered. You must examine the program's staff qualifications, curriculum details, and class size. You just look for <a href="https://nurturewithcarekidsacademy.com/programs/toddlers-in-oak-harbor">daycare programs for toddlers near Oak Harbor</a> that emphasize parent engagement and collaboration.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>3. What are the three most important elements that an early childhood program must have?</Accordion.Header>
                                            <Accordion.Body>
                                                An engaging learning environment, competent and caring teachers, and an inclusive curriculum are three important elements of any early childhood program. A productive ambiance encourages discovery and inventiveness through individual tutors who offer the <a href="https://www.nurturewithcarekidsacademy.com/">best childcare near Oak Harbor</a> and attention. A comprehensive syllabus combines play-based instruction, social-emotional development, and basic academics, leading to all-around growth among young learners.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>4. What is your child-to-teacher ratio?</Accordion.Header>
                                            <Accordion.Body>
                                                Nurture With Care Kids Academy - the best young children's learning center emphasizes personalized attention through varied child-teacher ratios based on age. They are as follows: 1:6 teacher-child ratio for infants aged 12 -21 months, 1:7 for toddlers aged 21 – 29 months, and 1:10 ratio for preschoolers aged between two and a half and five years.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>5. What qualifications do your young toddler's teachers hold?</Accordion.Header>
                                            <Accordion.Body>
                                                Passionate about their jobs, our young toddler teachers are also competent caregivers. They meet all state qualifications, which include a high school diploma, <a href="https://nurturewithcarekidsacademy.com/programs/preschool-in-oak-harbor">early learning preschool</a> qualifications, and specialized training in early childhood education (ECE). They offer the best early childhood development programs and young toddler activities for daycare. Hence, they have the required background knowledge to facilitate your baby's social, emotional, and cognitive development during this critical stage.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </section>
                </Layout>
            </div>
        </>
    )
}

export default Youngt