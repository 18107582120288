
import Services from '../components/Services';
import About from '../components/About';
import Teams from '../components/Teams';
import Gallery from '../components/Gallery';
import MainBanner from '../components/MainBanner';
import TestimonialSlider from '../components/TestimonialSlider';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../assets/js/Scrolltotop';
import Partners from '../components/Partners';

const Home = () => {
  return (
    <div id="main" className="home">
      <ScrollToTop />
      <Layout title="Best Childcare & Daycare in Oak Harbor, WA | Nurture with Care Kids Academy" description="Discover licensed daycare providers in Oak Harbor, WA, offering affordable childcare help for working parents. Contact us & find the perfect solution for your child.">
        <Helmet>
          <meta property="og:site_name" content="Nurture With Care Kids Academy" />
          <meta property="og:url" content="https://nurturewithcarekidsacademy.com/" />
          <meta property="og:title" content="Best Childcare & Daycare in Oak Harbor | Nurture with Care Kids Academy" />
          <meta property="og:type" content="website" />
          <meta property="og:description" content="Discover licensed daycare providers in Oak Harbor, WA, offering affordable childcare help for working parents. Contact us & find the perfect solution for your child." />
          <meta property="og:image" content="https://nurturewithcarekidsacademy.com/static/media/logo-nurture.fe8ff81585c61f3e8f0c.png" />
        </Helmet>
        <MainBanner />
        <About />
        <Teams />
        <Services />
        <Gallery />
        <TestimonialSlider/>
        <Partners/>
      </Layout>
    </div>
  )
}

export default Home