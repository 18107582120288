import { useState } from 'react';
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

const Gallery = () => {
    const slides = []
    for (let i = 1; i <= 8; i++) {
        var altTexts = [" Affordable child care near Oak Harbor", "Childcare providers near Oak Harbor", "Daycares in Oak Harbor WA", "Best toddler daycare in Oak Harbor", "Preschools in Oak Harbor WA", "Early Childhood Development Programs in Oak Harbor", "Best childcare near Oak Harbor", "Early Childhood Education Oak Harbor", "Preschool Learning Center in Oak Harbor", " Toddler programs near Oak Harbor", " Young children's learning center in Oak Harbor", "Toddler learning center Oak Harbor"];
        const slide = {
            src: `../../assets/images/gallery/nurture-gallery-${i}.jpg`,
            alt: altTexts[i - 1],
            width: 800,
            height: 800,
            srcSet: [
                { src: "/image1x320.jpg", width: 320, height: 213 },
                { src: `../../assets/images/gallery/nurture-gallery-${i}-small.jpg`, width: 640, height: 427 },
                { src: `../../assets/images/gallery/nurture-gallery-${i}-medium.jpg`, width: 1280, height: 850 },
                { src: `../../assets/images/gallery/nurture-gallery-${i}-large.jpg`, width: 1980, height: 1280 }
            ],
            uniqueId: i
        }

        slides.push(slide)
    }

    // for (let i = slides.length - 1; i >= 0; i--) {
    //     if (slides[i].uniqueId >= 4 && slides[i].uniqueId <= 10) {
    //         slides.splice(i, 1);
    //     }
    // }
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);

    const openLighbox = (id) => {
        setIndex(id - 1)
        setOpen(true)
    }
    const getImages = () => {
        const slidesHtml = []
        for (let slideh of slides) {

            const slideHtml = <div className="col-lg-3 col-md-6 col-sm-6" key={slideh.uniqueId}>
                <div className="polaroid-item">
                    <button className="btn-link" onClick={() => openLighbox(slideh.uniqueId)}>
                        <img alt={slideh.alt} src={slideh.src} className="img-responsive" />
                    </button>
                </div>
            </div>

            slidesHtml.push(slideHtml)
        }
        return slidesHtml
    }

    return (
        <>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                index={index}
                slides={slides}
                plugins={[Captions, Fullscreen, Slideshow, Zoom]}
            />
            <div id="gallery" className="container-fluid">
                <div className="row">
                    <div className="gallery-container">
                        <div className="section-heading">
                            <h2><span className='heading'></span>View <span className='text-red'>Gallery</span></h2>
                        </div>
                        <div className="polaroids margin1">
                            <div id="lightbox">
                                {getImages()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Gallery