import React, { useState, useRef, useEffect } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "./Gallery.css";
import ReactDOM from "react-dom";

import img1 from "../assets/new-gallery/2024/Patrick_s Day March 2024_/2024 st patrick.jpg";
import img2 from "../assets/new-gallery/2024/Patrick_s Day March 2024_/patrick day 2024.jpg";
import img3 from "../assets/new-gallery/2024/Patrick_s Day March 2024_/st patrick day 2024 .jpg";
import img4 from "../assets/new-gallery/2024/Patrick_s Day March 2024_/st patrick day image.jpg";
import img5 from "../assets/new-gallery/2024/Patrick_s Day March 2024_/st patrick.jpg";

import img6 from "../assets/new-gallery/2024/Reptile Zoo Feb 2024/2024-reptile-zoo-.jpg";
import img7 from "../assets/new-gallery/2024/Reptile Zoo Feb 2024/2024-reptile-zoo-image.jpg";
import img8 from "../assets/new-gallery/2024/Reptile Zoo Feb 2024/reptile-2024.jpg";
import img9 from "../assets/new-gallery/2024/Reptile Zoo Feb 2024/reptile-image.jpg";
import img10 from "../assets/new-gallery/2024/Reptile Zoo Feb 2024/reptile-zoo-event-.jpg";

import img11 from "../assets/new-gallery/2024/Valentine_s Day Feb 2024/2024-valentine-day-image.jpg";
import img12 from "../assets/new-gallery/2024/Valentine_s Day Feb 2024/2024-valentine_s.jpg";
import img13 from "../assets/new-gallery/2024/Valentine_s Day Feb 2024/valentine-day.jpg";
import img14 from "../assets/new-gallery/2024/Valentine_s Day Feb 2024/valentine_s-day-2024.jpg";
import img15 from "../assets/new-gallery/2024/Valentine_s Day Feb 2024/valentine_s-day-image-.jpg";

import img16 from "../assets/new-gallery/2023/Christmas Dec 2023/2023-christmas-.jpg";
import img17 from "../assets/new-gallery/2023/Christmas Dec 2023/2023-christmas-day.jpg";
import img18 from "../assets/new-gallery/2023/Christmas Dec 2023/chirstmas-day-2023-.jpg";
import img19 from "../assets/new-gallery/2023/Christmas Dec 2023/chirstmas-images-.jpg";
import img20 from "../assets/new-gallery/2023/Christmas Dec 2023/christmas-2023-.jpg";
import img21 from "../assets/new-gallery/2023/Christmas Dec 2023/santa-2023.jpg";

import img22 from "../assets/new-gallery/2023/Diwali Nov 2023/2023-diwali-celebration-.jpg";
import img23 from "../assets/new-gallery/2023/Diwali Nov 2023/DC.jpg";
import img24 from "../assets/new-gallery/2023/Diwali Nov 2023/diwali-celebration-.jpg";
import img25 from "../assets/new-gallery/2023/Diwali Nov 2023/diwali-celebration-2023.jpg";

import img26 from "../assets/new-gallery/2023/Halloween Nov 2023/2023-halloween-.jpg";
import img27 from "../assets/new-gallery/2023/Halloween Nov 2023/2023-halloween-image.jpg";
import img28 from "../assets/new-gallery/2023/Halloween Nov 2023/Untitled-design-(4).jpg";
import img29 from "../assets/new-gallery/2023/Halloween Nov 2023/halloween-2023.jpg";
import img30 from "../assets/new-gallery/2023/Halloween Nov 2023/halloween-pictures-.jpg";

import img31 from "../assets/new-gallery/2024/Preschool_graduation/preschool-grad-1.webp";
import img32 from "../assets/new-gallery/2024/Preschool_graduation/preschool-grad-2.webp";
import img33 from "../assets/new-gallery/2024/Preschool_graduation/preschool-grad-3.webp";
import img34 from "../assets/new-gallery/2024/Preschool_graduation/preschool-grad-4.webp";
import img35 from "../assets/new-gallery/2024/Preschool_graduation/preschool-grad-5.webp";

import img36 from "../assets/new-gallery/2024/Fathersday/fathersday_1.jpg";
import img37 from "../assets/new-gallery/2024/Fathersday/fathersday_2.jpg";
import img38 from "../assets/new-gallery/2024/Fathersday/fathersday_3.jpg";
import img39 from "../assets/new-gallery/2024/Fathersday/fathersday_4.jpg";
import img40 from "../assets/new-gallery/2024/Fathersday/fathersday_5.jpg";

import img41 from "../assets/new-gallery/2024/Halloween/halloween_1.jpg";
import img42 from "../assets/new-gallery/2024/Halloween/halloween_2.jpg";
import img43 from "../assets/new-gallery/2024/Halloween/halloween_3.jpg";
import img44 from "../assets/new-gallery/2024/Halloween/halloween_4.jpg";
import img45 from "../assets/new-gallery/2024/Halloween/halloween_5.jpg";
import img46 from "../assets/new-gallery/2024/Halloween/halloween_6.jpg";
import img47 from "../assets/new-gallery/2024/Halloween/halloween_7.jpg";
import img48 from "../assets/new-gallery/2024/Halloween/halloween_8.jpg";

import img49 from "../assets/new-gallery/2024/Oct_birth/birth_oct_1.jpg";
import img50 from "../assets/new-gallery/2024/Oct_birth/birth_oct_2.jpg";
import img51 from "../assets/new-gallery/2024/Oct_birth/birth_oct_3.jpg";
import img52 from "../assets/new-gallery/2024/Oct_birth/birth_oct_4.jpg";
import img53 from "../assets/new-gallery/2024/Oct_birth/birth_oct_5.jpg";

import img54 from "../assets/new-gallery/2024/Jul_birth/july_birth_1.jpg";
import img55 from "../assets/new-gallery/2024/Jul_birth/july_birth_2.jpg";

import img56 from "../assets/new-gallery/2024/Aug_birth/aug_birth_1.jpg";
import img57 from "../assets/new-gallery/2024/Aug_birth/aug_birth_2.jpg";
import img58 from "../assets/new-gallery/2024/Aug_birth/aug_birth_3.jpg";


import img59 from "../assets/new-gallery/2024/Christmas/christmas_24_1.jpeg";
import img60 from "../assets/new-gallery/2024/Christmas/christmas_24_2.jpeg";
import img61 from "../assets/new-gallery/2024/Christmas/christmas_24_3.jpeg";
import img62 from "../assets/new-gallery/2024/Christmas/christmas_24_4.jpeg";
import img63 from "../assets/new-gallery/2024/Christmas/christmas_24_5.jpeg";
import img64 from "../assets/new-gallery/2024/Christmas/christmas_24_6.jpeg";
import img65 from "../assets/new-gallery/2024/Christmas/christmas_24_7.jpeg";



const Gallery = () => {
  const eventsData = [
    {
      year: 2024,
      months: [
        {
          month: "December",
          events: [
            {
              eventName: "Christmas Day Event",
              images: [img59, img60, img61, img62, img63, img64, img65],
            },
          ],
        },
        {
          month: "October",
          events: [
            {
              eventName: "Halloween",
              images: [img41, img42, img43, img44, img45, img46, img48],
            },
            {
              eventName: "Birthday Celebration",
              images: [img49, img50, img51, img52, img53],
            },
          ],
        },
        {
          month: "August",
          events: [
            {
              eventName: "Preschool Graduation",
              images: [img31, img32, img33, img34, img35],
            },
            {
              eventName: "Birthday Celebration",
              images: [img56, img57, img58],
            },
          ],
        },
        {
          month: "July",
          events: [
            {
              eventName: "Birthday Celebration",
              images: [img54, img55],
            },
          ],
        },
        {
          month: "June",
          events: [
            {
              eventName: "Father's Day",
              images: [img36, img37, img38, img39, img40],
            },
          ],
        },
        {
          month: "March",
          events: [
            {
              eventName: "Patricks Day Event",
              images: [img1, img2, img3, img4, img5],
            },
          ],
        },
        {
          month: "February",
          events: [
            {
              eventName: "Reptile Zoo Event",
              images: [img6, img7, img8, img9, img10],
            },
            {
              eventName: "Valentine's Day Event",
              images: [img11, img12, img13, img14, img15],
            },
          ],
        },
      ],
    },
    {
      year: 2023,
      months: [
        {
          month: "December",
          events: [
            {
              eventName: "Christmas Day Event",
              images: [img16, img17, img18, img19, img20, img21],
            },
          ],
        },
        {
          month: "November",
          events: [
            { eventName: "Diwali Event", images: [img22, img23, img24, img25] },
            {
              eventName: "Halloween Event",
              images: [img26, img27, img28, img29, img30],
            },
          ],
        },
      ],
    },
  ];

  const [selectedImages, setSelectedImages] = useState(
    eventsData[0].months[0].events[0].images
  );
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 9;
  const [activeMonthIndex, setActiveMonthIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [dropdownContent, setDropdownContent] = useState(null);

  const hideDropdown = () => {
    setActiveMonthIndex(-1);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 991) {
        hideDropdown();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDropdownVisibility = (index) => {
    setActiveMonthIndex(activeMonthIndex === index ? null : index);
  };

  const handleMonthClick = (index, events, event) => {
    toggleDropdownVisibility(index);
    if (activeMonthIndex === index) {
      closeDropdown();
    } else {
      const targetRect = event.currentTarget.getBoundingClientRect();
      const dropdownWidth = 180;
      const viewportWidth = window.innerWidth;

      let calculatedLeft = targetRect.left + window.scrollX;

      if (calculatedLeft + dropdownWidth > viewportWidth) {
        calculatedLeft = viewportWidth - dropdownWidth;
      }

      setDropdownPosition({
        top: targetRect.bottom + window.scrollY,
        left: calculatedLeft,
      });

      setDropdownContent(
        <div
          className="dropdown-content"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {events.map((eventItem) => (
            <div
              key={eventItem.eventName}
              className="ms-3 my-2 btn btn-sm btn-light"
              onClick={() => {
                handleEventClick(eventItem.images);
                closeDropdown();
              }}
            >
              {eventItem.eventName}
            </div>
          ))}
        </div>
      );

      setActiveMonthIndex(index);
    }
  };

  const closeDropdown = () => {
    setActiveMonthIndex(-1);
    setDropdownContent(null);
  };

  const handleEventClick = (images) => {
    setLoading(true);
    setSelectedImages(images);
    setCurrentPage(1);
    setTimeout(() => {
      setLoading(false);
    }, 1400);
  };

  const openLightbox = (index) => {
    setCurrentImageIndex((currentPage - 1) * imagesPerPage + index);
    setLightboxOpen(true);
  };

  const lastIndex = currentPage * imagesPerPage;
  const firstIndex = lastIndex - imagesPerPage;
  const currentImages = selectedImages.slice(firstIndex, lastIndex);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const pageCount = Math.ceil(selectedImages.length / imagesPerPage);

  const toggleMonthDropdown = (index) => {
    setActiveMonthIndex(activeMonthIndex === index ? null : index);
  };

  return (
    <div id="page-section">
      <section
        id="blog"
        style={{ paddingTop: "0" }}
        className="gallery-page-section"
      >
        <div className="container-fluid">
          <div className="jumbo-heading">
            <h1>Gallery</h1>
          </div>
          <div className="gallery-sidebar-container mobile-sidebar p-0">
            {eventsData.map((year, yearIndex) => (
              <div className="sidebar-item" key={year.year}>
                <div className="sidebar-year">
                  <h4>{year.year}</h4>
                </div>
                <div className="e-month">
                  {year.months.map((month, monthIndex) => (
                    <div key={month.month} style={{ position: "relative" }}>
                      <h5
                        className="dropdown-toggle"
                        onClick={(e) => {
                          handleMonthClick(
                            yearIndex * 100 + monthIndex,
                            month.events,
                            e
                          );
                        }}
                      >
                        {month.month}
                        <b className="caret"></b>
                      </h5>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {dropdownContent &&
            ReactDOM.createPortal(
              <div
                style={{
                  position: "absolute",
                  top: `${dropdownPosition.top}px`,
                  left: `${dropdownPosition.left}px`,
                  zIndex: 1000,
                }}
                className="gallery-dropdown-content"
              >
                {dropdownContent}
              </div>,
              document.body
            )}
        </div>

        <div className="container gallery-page-container">
          <div className="row">
            <div className="gallery-sidebar-container full-siderbar col-md-3 p-0">
              {eventsData.map((year, yearIndex) => (
                <div className="sidebar-item" key={year.year}>
                  <div className="sidebar-year">
                    <h4>{year.year}</h4>
                  </div>
                  <div className="e-month">
                    {year.months.map((month, monthIndex) => (
                      <div key={month.month}>
                        <h5
                          className="dropdown-toggle"
                          onClick={() =>
                            toggleMonthDropdown(yearIndex * 100 + monthIndex)
                          }
                        >
                          {month.month}
                          <b className="caret"></b>
                        </h5>
                        <div
                          className={`dropdown-content ${activeMonthIndex === yearIndex * 100 + monthIndex
                              ? "show"
                              : "collapse"
                            }`}
                        >
                          {month.events.map((event) => (
                            <div
                              key={event.eventName}
                              className="ms-3 my-2 btn btn-sm btn-light"
                              onClick={() => handleEventClick(event.images)}
                              style={{ margin: "10px 0" }}
                            >
                              {event.eventName}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="gallery-images-container col-md-9 p-0">
              <div className="row row-cols-1 row-cols-md-3 g-4">
                {loading ? (
                  <div className="spinner-border" role="status">
                    <span>Loading...</span>
                  </div>
                ) : (
                  currentImages.map((src, index) => (
                    <div key={index} className="col-md-4">
                      <img
                        src={src}
                        alt="Event"
                        className="img-fluid"
                        onClick={() => openLightbox(index)}
                      />
                    </div>
                  ))
                )}
              </div>
              {lightboxOpen && (
                <Lightbox
                  open={lightboxOpen}
                  index={currentImageIndex}
                  slides={selectedImages.map((src) => ({ src }))}
                  close={() => setLightboxOpen(false)}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Gallery;