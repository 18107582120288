import preschool from '../assets/images/preschool-new.webp'
import toddlers from '../assets/images/home-tod.webp'
import youngTod from "../assets/images/home-young.webp";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import girl from "../assets/images/sleep-girl.png"
import kutta from "../assets/images/kutta.png"

const Services = () => {
  const iniModal = {
    young: '',
    tod: '',
    pre: ''
  }
  const [modal, setModal] = useState(iniModal)
  function onHandleModal(modalId) {
    const newModal = { ...iniModal }
    if (iniModal) {
      newModal[modalId] = 'display-block in'
    }
    setModal(newModal)
  }

  return (
    <section id="services" className="container">
      <div className="section-heading">
        <h2><span className='heading'></span> Our <span className='text-red'>Programs</span></h2>
      </div>

      <div className="parallax-obj1 hidden-sm hidden-xs service-para1" data-0="opacity:1;"
        data-100="transform:translatey(40%);"
        data-center-center="transform:translatey(-60%);">
        <img src={kutta} alt="toddler education programs" />
      </div>

      <div className="row rowflex">
        <div className="col-md-4 mb-2r">
          <div className="serviceBox bg-color2" style={{ backgroundColor: "#E9FDFF" }}>

            <img src={youngTod} className="img-responsive img-circle" alt="toddler childcare activities in Oak Harbor" />

            <div className="service-content">
              <h4 className="title text-light-blue pb-0 mb-0">Young Toddlers</h4>
              <h6 className='text-light-blue'>Full-time and Part-time</h6>
              <p className="description text-light-blue">
                Welcome to our Young Toddler Program at Nurture with Care Kids Academy! Designed for curious minds aged 12 – 24 months, this program provides <a href=" https://nurturewithcarekidsacademy.com/programs/young-toddlers-in-oak-harbor">young toddler activities</a>  in a nurturing environment where young learners can explore, discover, and develop foundational skills.
              </p>
              <br></br>

              <div className={`modal fade ${modal.young}`} aria-hidden="true">
                <div className="modal-dialog min-width-80">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => onHandleModal('')}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h4 className="modal-title pb-0 mb-0">Young Toddlers</h4>
                    </div>
                    <div className="modal-body">
                      <ul className="custom no-margin text-normal text-left">
                        <li className="program-li-before"><strong>Individualized Care:</strong> Our experienced caregivers understand the unique needs of young toddlers. We maintain a low child-to-caregiver ratio to ensure personalized attention.</li>
                        <li className="program-li-before"><strong>Safe Exploration:</strong> We offer a safe and stimulating space for your child to explore their surroundings. Our play areas are designed to encourage active engagement and cognitive development.</li>
                        <li className="program-li-before"><strong>Sensory Activities:</strong> Young toddlers learn through their senses. Our program includes sensory-rich activities that encourage sensory exploration, creativity, and motor skill development.</li>
                        <li className="program-li-before"><strong>Introduction to Social Interaction:</strong> We facilitate gentle interactions between young toddlers, helping them develop social skills, empathy, and an early understanding of community.</li>
                        <li className="program-li-before"><strong>Daily Communication:</strong> We maintain open lines of communication with parents/guardians to keep you informed about your child's day, including meals, naps, and activities.</li>
                      </ul>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => onHandleModal('')}>Close</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <Link to="/programs/young-toddlers-in-oak-harbor" className="btn btn-primary text-ligh btn-outline" >
              <h6>Read more</h6>
            </Link>

          </div>
        </div>
        <div className="col-md-4 mb-2r">
          <div className="serviceBox bg-color2" style={{ backgroundColor: "#D9F8FC" }}>

            <img src={toddlers} className="img-responsive img-circle" alt="day care activities for toddlers near Oak Harbor" />

            <div className="service-content ">
              <h4 className="title text-light-blue pb-0 mb-0">Toddlers</h4>
              <h6 className='text-light-blue'>Full-time and Part-time</h6>
              <p className="description text-light-blue">
                Our  <a href="https://nurturewithcarekidsacademy.com/programs/toddlers-in-oak-harbor">best toddler daycare</a> at the affordable child care near Oak Harbor is tailored to the needs of active explorers aged 24 months – 36 months. We provide a structured yet flexible environment that fosters curiosity, independence, and early learning.
              </p>
              <br></br>


              <div className={`modal fade ${modal.tod}`} aria-hidden="true">
                <div className="modal-dialog min-width-80">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => onHandleModal('')}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h4 className="modal-title">Toddlers</h4>
                    </div>
                    <div className="modal-body">
                      <ul className="custom no-margin text-normal text-left">
                        <li><strong>Age-Appropriate Curriculum:</strong> Our curriculum is designed to stimulate cognitive, language, and motor skill development. We offer a blend of structured learning and play-based exploration.</li>
                        <li><strong>Language Development:</strong> Through stories, songs, and engaging activities, we focus on expanding toddlers' vocabulary and communication skills, setting the foundation for future learning.</li>
                        <li><strong>Creative Expression:</strong> Toddlers love to express themselves. Our program includes art, music, and movement activities that encourage creativity and self-expression.</li>
                        <li><strong>Social Skills:</strong> Toddlers are introduced to group activities that promote sharing, taking turns, and cooperative play. These experiences foster essential social skills and emotional development.</li>
                        <li><strong>Potty Training Support:</strong> For those who are ready, our experienced staff provides gentle potty training support, working in partnership with parents/guardians.</li>
                      </ul>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => onHandleModal('')}>Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Link to="/programs/toddlers-in-oak-harbor" className="btn btn-primary text-light btn-outline" >
              <h6>Read more</h6>
            </Link>
          </div>
        </div>
        <div className="col-md-4 mb-2r">
          <div className="serviceBox bg-color2" style={{ backgroundColor: "#C8F1F6" }}>

            <img src={preschool} className="img-responsive img-circle" alt="preschool learning center in Oak Harbor" />

            <div className="service-content">
              <h4 className="title text-light-blue pb-0 mb-0">Pre-School</h4>
              <h6 className='text-light-blue'>Full-time and Part-time</h6>
              <p className="description text-light-blue">
                Prepare your child for a lifelong love of learning with our Pre-school Program at Nurture with Care Kids Academy. Geared toward children aged 3 – 6 years, our <a href="https://nurturewithcarekidsacademy.com/programs/preschool-in-oak-harbor"> preschool learning center</a> focuses on kindergarten readiness and holistic development.
              </p>
              <br></br>


              <div className={`modal fade ${modal.pre}`} aria-hidden="true">
                <div className="modal-dialog min-width-80">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => onHandleModal('')}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h4 className="modal-title">Pre-School</h4>
                    </div>
                    <div className="modal-body">
                      <ul className="custom no-margin text-normal text-left">
                        <li><strong>Structured Learning:</strong> Our curriculum follows a structured approach that encompasses language, math, science, and more. Children engage in age-appropriate activities that challenge and inspire them.</li>
                        <li><strong>Literacy and Numeracy:</strong> We place a strong emphasis on building early literacy and numeracy skills, fostering a solid foundation for academic success.</li>
                        <li><strong>Critical Thinking:</strong> Our program encourages critical thinking and problem-solving through hands-on activities, puzzles, and games that stimulate cognitive growth.</li>
                        <li><strong>Character Development:</strong> We foster character traits such as kindness, empathy, and responsibility through stories, discussions, and cooperative projects.</li>
                        <li><strong>Project-Based Learning: </strong>Children participate in themed projects that encourage research, collaboration, and creativity, promoting a well-rounded skill set.</li>
                        <li><strong>Parent Involvement: </strong> We maintain open communication with parents/guardians, providing regular updates on your child's progress and involving families in various aspects of their learning journey.</li>
                      </ul>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => onHandleModal('')}>Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Link to="/programs/preschool-in-oak-harbor" className="btn btn-primary text-light pa-10 btn-outline" >
              <h6>Read more</h6>
            </Link>
          </div>
        </div>
      </div>
      <div className="parallax-obj1 hidden-sm hidden-xs service-para2" data-0="opacity:1;"
        data-100="transform:translatey(40%);"
        data-center-center="transform:translatey(-60%);">
        <img src={girl} alt="toddler education programs" />
      </div>
    </section>
  )
}

export default Services