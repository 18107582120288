import { useState } from 'react';
import axios from 'axios';
import parentHandbook from '../meta/NurturewithCareParenthandbook-v2.1_compressed.pdf'
import healthPolicy from '../meta/Nurture_with_Care_Health_care_policy.pdf'
import emergency from '../meta/Nurture_with_Care_Emergency_preparedness_Plan.pdf'
import { Link } from 'react-router-dom'

import here from "../assets/images/here.png"
import girl from "../assets/images/full-shot-little-girl-studio 1.png";
import girl2 from "../assets/images/asaeqw25 1.png"
import fb from "../assets/images/facebook.png";
import instagram from "../assets/images/instagram.png"

const Footer = () => {
  const initialState = {
    email: ""
  }
  const [state, setState] = useState(initialState)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [isSubmit, setIsSubmit] = useState(false)

  const closeAlert = () => {
    setSuccess("")
    setError("")
    setIsSubmit(false)
  }

  const onChange = (field, event) => {
    closeAlert()

    const updatedState = { ...state }

    updatedState[field] = event.target.value

    setState(updatedState)
  }

  const onSubmit = async () => {
    closeAlert()
    const payload = {
      email: state.email
    }

    const errors = []

    if (payload.email === '') {
      errors.push('Email can not be empty.')
    }

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (payload.email !== '' && !emailRegex.test(payload.email)) {
      errors.push('Email is not valid.')
    }

    if (errors.length > 0) {
      const newError = errors.map((e) => `<li>${e}</li>`)
      setError(`<ul class="text-left">${newError}</ul>`)
    } else {
      setIsSubmit(true)
      try {
        const { data } = await axios.post('/scripts/NewsLetter.php', payload)
        closeAlert()
        if (data.status === 'Error') {
          setError(data.message)
        } else {
          setSuccess(data.message)
        }
        setTimeout(() => {
          closeAlert()
        }, 5000);
      } catch (e) {
        closeAlert()
        setError(`Something went wrong. Please try again later.`)
        setTimeout(() => {
          closeAlert()
        }, 5000);
      }
    }

  }

  return (
    <>
      <div className="footer-section">
        <div className="footer-container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="footer-location">
                  <ul>
                    <li className='text-light-blue'>Email: <a href="mailto:info@nurturewithcarekidsacademy.com" className='text-light-blue'>info@NurtureWithCareKidsAcademy.com</a></li>
                    <li className='text-light-blue'>Phone: <a href="tel:+1 360-682-8920" className='text-light-blue'>+1 360-682-8920</a></li>
                    <li className='text-green'>Location 1 : 1162 SW Fort Nugent Ave, Oak Harbor, WA 98277 <br /> Hours of Operation: 7AM - 6PM</li>
                    <li className='text-red'>Location 2: 1117 View Ridge Dr, Oak Harbor, WA 98277 <br /> Hours of Operation: 8AM - 5PM</li>
                  </ul>
                  <div className='hr'></div>
                  <a href="https://www.himama.com/online_registration/apply/nurture-with-care-kids-academy" target='_blank' rel="noreferrer" className='btn'>Enroll Now</a>
                </div>
              </div>
              <div className="col-md-6">
                <img src={here} alt="" className='here-img' />
              </div>
            </div>
            <div className="parallax-obj2 hidden-sm hidden-xs footer-para2" data-0="opacity:1;"
              data-100="transform:translatey( 40%);"
              data-center-center="transform:translatey(-60%);">
              <img src={girl2} alt="licensed daycare providers near Oak Harbor" />
            </div>
          </div>
        </div>

        <footer>
          <div className="newsletter">
            <h3 className="newsletter-title">SUBSCRIBE TO OUR NEWSLETTER</h3>
            <form className="newsletter-form" >
              <input
                type="email"
                placeholder="Enter your email"
                value={state.email} onChange={(event) => onChange('email', event)}
                className="newsletter-input"
                required
              />
              <button className="newsletter-button" onClick={() => onSubmit()}>
                SUBSCRIBE
              </button>
              {isSubmit ? <label className="alert alert-info response">Subscribing... Please wait!</label> : null}
              {error !== '' ? <label className="alert alert-danger response" dangerouslySetInnerHTML={{ __html: error }}></label> : null}
              {success !== '' ? <label className="alert alert-success response text-black white-bg" dangerouslySetInnerHTML={{ __html: success }}></label> : null}

            </form>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <h6 className="text-black">Nurture With Care Kids Academy</h6>
                <p className="text-black font-salsa" style={{ maxWidth: "320px" }}>Welcome to Nurture with Care Kids Academy, where every child's journey is embraced with love, growth, and a passion for learning. We believe that a nurturing environment lays the foundation for a lifetime of success, and it's our privilege to be a part of your child's early years.
                </p>
              </div>

              <div className="col-md-4">

                <h6 className="text-black">Useful Resources</h6>

                <ul className="custom no-margin text-normal text-black text-left font-salsa">
                  <li><Link to="/faqs" rel="noreferrer">FAQ</Link></li>
                  <li><a href={parentHandbook} target="_blank" rel="noreferrer">Parent Handbook</a></li>
                  <li><a href={healthPolicy} target="_blank" rel="noreferrer">Health Policy</a></li>
                  <li><a href={emergency} target="_blank" rel="noreferrer">Emergency Preparedness Plan</a></li>
                  <li><Link to="/terms" rel="noreferrer">Terms of Service</Link></li>
                  <li><Link to="/privacy" rel="noreferrer">Privacy Policy</Link></li>
                  <li><Link to="/career" rel="noreferrer">Career</Link></li>
                  <li><Link to="/enroll" rel="noreferrer">Enrollment Forms</Link></li>
                </ul>
              </div>

              <div className="col-md-2 res-margin">
                <h6 className="text-black">Follow Us</h6>
                <ul className="list-unstyled footer-icons font-salsa">
                  <li className="text-black" style={{ marginBottom: "5px" }}><a href="https://www.facebook.com/NurturewithCareKidsAcademy/" target="_blank" rel="noreferrer" title="" className="text-black"><img src={fb} alt="" style={{ width: "30px", marginBottom: "5px", marginRight: "10px" }} /> Facebook</a></li>
                  <li className="text-black"><a href="https://www.instagram.com/nwc.oakharbor/" target="_blank" rel="noreferrer" title="" className="text-black"><img src={instagram} alt="" style={{ width: "30px", marginBottom: "5px", marginRight: "10px" }} /> Instagram</a></li>
                </ul>
              </div>

              <div className="credits col-md-12 text-center text-black">
                <hr />
                {`Copyright © ${new Date().getFullYear()} Nurture with Care Kids Academy. All rights reserved.`}

                <div className="page-scroll hidden-sm hidden-xs">
                  <a href="#page-top" className="back-to-top "><i className="fa fa-angle-up text-black"></i></a>
                </div>
              </div>

            </div>
            <div className="parallax-obj2 hidden-sm hidden-xs footer-para1" data-0="opacity:1;"
              data-100="transform:translatey( 40%);"
              data-center-center="transform:translatey(-60%);">
              <img src={girl} alt="licensed daycare providers near Oak Harbor" />
            </div>
          </div>

        </footer>
      </div>
    </>
  )
}

export default Footer