import React from "react";

const Map = () => {
    return (
        <div className="container-fluid">
            <div>
            <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d21239.30835828043!2d-122.69229772343138!3d48.28527910413366!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548f8bd56549e27f%3A0xc8be9aef9af36161!2sNurture%20with%20Care%20Kids%20Academy!5e0!3m2!1sen!2sin!4v1701687589759!5m2!1sen!2sin" width="600" height="350" style={{border:"0px", width:"100%", borderRadius:"20px"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    )
}

export default Map