import { useState } from 'react';
import axios from 'axios';
import Map from '../components/Map';
const Contact = () => {

  const initialState = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  }

  const [state, setState] = useState(initialState)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [isSubmit, setIsSubmit] = useState(false)

  const closeAlert = () => {
    setSuccess("")
    setError("")
    setIsSubmit(false)
  }

  const onChange = (field, event) => {
    closeAlert()
    const updatedState = { ...state }

    updatedState[field] = event.target.value

    setState(updatedState)
  }

  const onSubmit = async () => {
    closeAlert()
    const payload = {
      name: state.name,
      email: state.email,
      phone: state.phone,
      subject: state.subject,
      message: state.message
    }


    const errors = []

    if (payload.name === '') {
      errors.push('Name can not be empty.')
    }

    if (payload.email === '') {
      errors.push('Email can not be empty.')
    }

    //const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const gmailRegex = /^[^\s@]+@gmail\.com$/;

    if (payload.email !== '' && !emailRegex.test(payload.email) && !gmailRegex.test(payload.email)) {
      errors.push('Email is not valid.')
    }

    if (payload.phone === '') {
      errors.push('Phone can not be empty.')
    }

    const numRegex = /^\+?1?\s*[-]?\(?(\d{3})\)?[-]?\s*[-]?(\d{3})[-]?\s*[-]?(\d{4})$/
    if (payload.phone !== '' && !numRegex.test(payload.phone)) {
      errors.push('Phone is not valid.')
    }

    if (payload.subject === '') {
      errors.push('Subject can not be empty.')
    }

    if (payload.message === '') {
      errors.push('Message can not be empty.')
    }


    if (errors.length > 0) {
      const newError = errors.map((e) => `<li>${e}</li>`).join('')
      setError(`<ul class="text-left">${newError}</ul>`)
    } else {
      setIsSubmit(true)
      try {
        const { data } = await axios.post('/scripts/ContactUs.php', payload)
        closeAlert()
        if (data.status === 'Error') {
          setError(data.message)
        } else {
          setSuccess(data.message)
        }
        setTimeout(() => {
          closeAlert()
        }, 5000);
      } catch (e) {
        setError(`Something went wrong. Please try again later.`)
        setTimeout(() => {
          closeAlert()
        }, 5000);
      }
    }

  }

  return (

    <section id="contact" className="nobg-small">
      <div className="row">
        <div className='col-md-11 col-lg-11'>
          <div className="tagline">If you are ready to enroll in your child, please complete the information at <a href="https://www.himama.com/online_registration/apply/nurture-with-care-kids-academy" rel='noreferrer' target="_blank">https://www.himama.com/online_registration/apply/nurture-with-care-kids-academy</a>.
            <span>  This should not take more than a few minutes.</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          {/* <div id="contact_form" >
              <h4>Send Us a Message</h4>
              <div className="form-group">
                <label>Name: *</label><input type="text" value={state.name} className="form-control input-field" onChange={(event) => onChange('name', event)} />
                <label>Email: *</label><input type="email" value={state.email} className="form-control input-field" onChange={(event) => onChange('email', event)} />
                <label>Phone:</label><input type="text" value={state.phone} className="form-control input-field" onChange={(event) => onChange('phone', event)} />
                <label>Subject: *</label><input type="text" value={state.subject} className="form-control input-field" onChange={(event) => onChange('subject', event)} />
              </div>
              <label>Message: *</label>
              <textarea name="message" className="textarea-field form-control" rows="4" value={state.message} onChange={(event) => onChange('message', event)}></textarea>
              <button className="btn center-block" onClick={() => onSubmit()}>Send message</button>
              {isSubmit ? <div className="alert alert-info response">Working on your request... Please wait!</div> : null}
              {error !== '' ? <div className="alert alert-danger response" dangerouslySetInnerHTML={{ __html: error }}></div> : null}
              {success !== '' ? <div className="alert alert-success response" dangerouslySetInnerHTML={{ __html: success }}></div> : null}
            </div> */}

          {/* <div id="contact_results"></div> */}
          <iframe title='contact-form' src="https://docs.google.com/forms/d/e/1FAIpQLSd5ALGGdZUYsuryyVnhoaBQ2C4Lc3C-GSTUnkXJsZhSTvxMcw/viewform?embedded=true" width="100%" height="1309" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
        </div>
        <div className="col-md-6">
          <div className="well text-left pt-0 pl20">
            <h4>Information</h4>

            <div className="contact-info ">
              <p><i className="fa fa-envelope margin-icon"></i>Email: <a href="mailto:info@nurturewithcarekidsacademy.com">info@NurtureWithCareKidsAcademy.com</a></p>
              <p><i className="fa fa-phone margin-icon"></i>Phone: <a href="tel:+1 360-682-8920">+1 360-682-8920</a></p>
              <p style={{ marginTop: "30px" }}><i className="fa fa-map-marker margin-icon"></i>Address 1: 1162 SW Fort Nugent Ave, Oak Harbor, WA 98277</p>
              <p><i className="fa fa-clock-o margin-icon"></i>Hours of Operation: 7AM - 6PM</p>
              <Map />
              <p style={{ marginTop: "20px" }}><i className="fa fa-map-marker margin-icon"></i>Address 2: 1117 View Ridge Dr, Oak Harbor, WA 98277</p>
              <p><i className="fa fa-clock-o margin-icon"></i>Hours of Operation: 8AM - 5PM</p>
              <div className="container-fluid">
                <div>
                  <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d42493.02949811173!2d-122.71169458929778!3d48.26794724519013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548f8b92608cd139%3A0xcc3919ab686d46c!2sNurture%20With%20Care%20Kids%20Academy!5e0!3m2!1sen!2sin!4v1728452508333!5m2!1sen!2sin" width="600" height="350" style={{ border: "0px", width: "100%", borderRadius: "20px" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default Contact