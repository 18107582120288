import Accordion from 'react-bootstrap/Accordion';

import preschool1 from '../assets/images/services/pre-school-program2.jpg'
import preschool2 from '../assets/images/services/pre-school-oak.png'
import structured from '../assets/images/services/structured-earning.svg'
import numeracy from '../assets/images/services/numeracy-skills.svg'
import critical from '../assets/images/services/critical.svg'
import character from '../assets/images/services/character.svg'
import themed from '../assets/images/services/themed.svg'
import parent from '../assets/images/services/parent.svg'

import girl from "../assets/images/sleep-girl.png"
import kutta from "../assets/images/kutta.png"

import hand from "../assets/images/hand.png"
import star from '../assets/images/stars.png'

import Layout from '../components/layout';
import ScrollToTop from '../assets/js/Scrolltotop'
import { Helmet } from 'react-helmet'

const Preschool = () => {
    return (
        <>
            <ScrollToTop />
            <div id="page-section">
                <Layout title="PreSchool Program in Oak Harbor | Nurture With Care Kids Academy" description="Discover the best early learning preschool with a nurturing environment and experienced teachers at Nurture With Care Kids Academy. Enroll your child today.">
                    <Helmet>
                        <meta property="og:site_name" content="Nurture With Care Kids Academy" />
                        <meta property="og:url" content="https://nurturewithcarekidsacademy.com/programs/preschool-in-oak-harbor" />
                        <meta property="og:title" content="Preschool Program in Oak Harbor" />
                        <meta property="og:type" content="article" />
                        <meta property="og:description" content="Discover the best early learning preschool with a nurturing environment and experienced teachers at Nurture With Care Kids Academy. " />
                        <meta property="og:image" content="https://nurturewithcarekidsacademy.com/static/media/preschool.3d02f9e76d3c797fc013.jpg" />

                        <script type="application/ld+json">
                            {JSON.stringify(
                                {
                                    "@context": "https://schema.org",
                                    "@type": "FAQPage",
                                    "mainEntity": [{
                                        "@type": "Question",
                                        "name": "What skills are preschool children actually learning in the dramatic play center?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "The preschoolers' social skills, language skills, and imagination in pretend play are developed through dramatic play centers at Nurture with Care Kids Academy. Children creatively express themselves and collaborate, offering negotiation."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "What is not one of the key features of a highly structured preschool program?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Typically, highly structured preschool programs place a lot of emphasis on organized activities, socialization, and cognitive development, but they do not prioritize flexibility and spontaneity which are central to developing creativity among children. Not like the value attached to early childhood development programs by Nurture with Care Kids Academy which is well known for its innovation, some daycares in Oak Harbor WA may forget this part and do not give the best toddler daycare."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "How do preschool programs account for all children in an emergency?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "There are emergency procedures ready to ensure all children are safe at Nurture with Care Kids Academy. These procedures at our preschool programs near Oak Harbor include evacuation drills or even head count protocols."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "How to apply for the preschool program?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "To enroll in our preschool learning center, visit our website or contact us directly. You will need to fill out and submit the online application form and any other necessary papers. From that point, we will guide you through everything while you ask questions."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "What is the duration of the preschool program?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "The duration of our preschools in Oak Harbor WA, is from 9 am to 4 pm. This time frame allows for a complete curriculum in varied subjects and activities that promote intellectual development, social skills, and emotional well-being."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "What are some tips for preparing your child for preschool?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Some things you can do to prepare your child for early learning preschool include having routines, reading books on school-going, and encouraging independence in simple tasks like dressing or eating. Just before the child begins attending preschool, invite friends over so that they may become familiar with strangers."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "What are some common concerns that parents have about preschool?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Parents' common issues about Oak Harbor preschool education include separation anxiety, academic preparation, safety, and socialization. They may be concerned whether their child will fit in the new setting and mingle with his mates as expected to develop himself. The concern for separation anxiety, academic readiness, safety, and socialization among parents who take their children to Oak Harbor preschool education is addressed by Nurture with Care Kids Academy through open communication that eases their worry about their child’s adjustment and development."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "What are some signs that your child has difficulty adjusting to preschool?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Some signals that your kid might have problems adjusting to preschool consist of being overly possessive at drop-off time, withdrawing from activities or companions while the early childhood development programs, excessive crying or tantrums, physical symptoms such as headaches or stomach aches, and developmental regression signs. Early intervention is possible by speaking to teachers and observing your children's behavior."
                                        }
                                    }]
                                }

                            )}</script>
                    </Helmet>

                    <section className="services" style={{ paddingTop: "0" }}>
                        <div className="container-fluid">
                            <div className="jumbo-heading">
                                <h1>Preschool Program in Oak Harbor</h1>
                            </div>
                            <div className="parallax-obj1 hidden-sm hidden-xs service-para1 service-p" data-0="opacity:1;"
                                data-100="transform:translatey(40%);"
                                data-center-center="transform:translatey(-60%);">
                                <img src={kutta} alt="toddler education programs" />
                            </div>
                            <div className="container ">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4>Discover Our Preschool Program in Oak Harbor, WA</h4>
                                        <p>Welcome to our preschool learning center, where young minds grow and thrive in a nurturing environment. As a trusted early learning preschool in View Ridge & Fort Nugent, Oak Harbor, WA, we provide a comprehensive curriculum designed to inspire curiosity, foster development, and lay a strong foundation for lifelong success.</p>
                                        <ul>
                                            <li><strong>Comprehensive Preschool Curriculum: </strong> At our center, structured learning is at the heart of everything we do. Our curriculum includes language, math, science, and creative arts, offering a balanced approach to early education. Each activity is designed to challenge and engage children, ensuring they receive a well-rounded educational experience.</li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={preschool1} alt="preschool in oak harbor" className="img-curved  center-block img-responsive" />
                                    </div>
                                </div>
                                <div className="row mt-30">
                                    <div className="col-md-6">
                                        <img src={preschool2} alt="preschool in oak harbor" className="img-curved  center-block img-responsive" />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <ul>
                                            <li><strong>Building Early Literacy and Numeracy Skills: </strong> Our program focuses on early literacy and numeracy skills. The children learn to love reading and problem-solving through fun, interactive lessons and engaging activities. This prepares them for future academic success, making us one of the best preschools in Oak Harbor, WA.</li>
                                            <li><strong>Fostering Critical Thinking and Problem-Solving: </strong> Our hands-on activities, puzzles, and group tasks are all designed to develop critical thinking and problem-solving skills. These exercises stimulate cognitive growth and encourage a mindset that values exploration and inquiry, setting our program apart from other preschool programs near Oak Harbor.</li>
                                            <li><strong>Focus on Character Development: </strong> Through activities, stories, and cooperative projects, we encourage the traits of kindness, empathy, and responsibility. This keeps the child busy emotionally and socially, better preparing them for positive interactions and good friendships that last a lifetime.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="parallax-obj1 hidden-sm hidden-xs service-para2 service-p" data-0="opacity:1;"
                                data-100="transform:translatey(40%);"
                                data-center-center="transform:translatey(-60%);">
                                <img src={girl} alt="toddler education programs" />
                            </div>
                        </div>
                    </section>

                    <section className="facilities bg-color4">
                        <div className="parallax-obj1 hidden-sm hidden-xs team-para service-p" data-0="opacity:1;"
                            data-100="transform:translatey(40%);"
                            data-center-center="transform:translatey(-60%);">
                            <img src={star} alt="toddler education programs" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div className="section-heading">
                                        <h2><span className='heading'></span>Facilities for <span className='text-red'>Preschoolers </span></h2>
                                    </div>
                                    <p>We are the best preschool in Oak Harbor, WA, and we do more than provide childcare services. Our focus is to create a safe and positive environment where kids can grow within academic and social-emotional bases.
                                    </p>
                                    <p>From structured learning to creative exploration, our program is tailored to meet the needs of every child. Visit our early learning preschool and see why we are a trusted name among preschools in Oak Harbor, WA. Let us guide your child on a discovery, growth, and success journey.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={structured} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>A Holistic Approach to Preschool Learning</h3>
                                        <p>Our preschool learning center provides a structured and enriching environment that sets the foundation for children's academic and personal success. Located in View Ridge and Fort Nugent, Oak Harbor, WA, our center stands out among preschools in Oak Harbor, WA, offering an engaging curriculum that fosters growth and curiosity.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={structured} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Structured Learning for Holistic Growth</h3>
                                        <p>Our structured curriculum covers the core subjects of language, math, and science. Age-appropriate activities inspire children to explore, learn, and thrive. Parents seeking preschool programs near Oak Harbor will appreciate our fun and educational rigor balance.

                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={numeracy} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Focus on Literacy and Numeracy</h3>
                                        <p>We focus on early literacy and numeracy skills as an early learning preschool. Activities are designed to ignite a love for reading and numbers, building a solid foundation for future academic achievement.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={critical} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Fostering Critical Thinking</h3>
                                        <p>Critical thinking and problem-solving are part of our curriculum. Through hands-on activities, puzzles, and interactive games, children develop cognitive abilities that help them approach challenges with creativity and confidence. If you are searching for a kids' preschool near Oak Harbor that fosters intellectual growth, look no further.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={character} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Character Development Matters</h3>
                                        <p> At Nurture with Care Kids Academy, we focus on developing a child's character. We teach the little ones to be kind, understanding, and responsible through stories, discussions, and group projects to help them grow into compassionate and well-rounded children.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={themed} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Creative Exploration Through Project-Based Learning</h3>
                                        <p> Our thematic, project-based method encourages kids to work in teams, investigate, and exercise their imagination. The engaging activities enable the building of diverse skills that make our preschool stand out in Oak Harbor, WA.

                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={parent} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Partnering with Parents </h3>
                                        <p> We appreciate parental involvement and ensure ongoing communication with parents to support a collaborative educational experience. Parents are informed of the child's development, ensuring an open and nurturing relationship between home and our preschool learning center.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="parallax-obj1 hidden-sm hidden-xs team-para5 inner-service-para service-p" data-0="opacity:1;"
                            data-100="transform:translatey(40%);"
                            data-center-center="transform:translatey(-60%);">
                            <img src={hand} alt="toddler education programs" />
                        </div>
                    </section>


                    <section>
                        <div className='container'>
                            <div className="row">
                                <div className="section-heading">
                                    <h2><span className='heading'></span>FAQ'S</h2>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: "20px" }}>
                                <div className="col-md-12">
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>1. What skills are preschool children actually learning in the dramatic play center?</Accordion.Header>
                                            <Accordion.Body>
                                                The preschoolers' social skills, language skills, and imagination in pretend play are developed through dramatic play centers at Nurture with Care Kids Academy. Children creatively express themselves and collaborate, offering negotiation.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>2. What is not one of the key features of a highly structured preschool program?</Accordion.Header>
                                            <Accordion.Body>
                                                Typically, highly structured preschool programs place a lot of emphasis on organized activities, socialization, and cognitive development, but they do not prioritize flexibility and spontaneity which are central to developing creativity among children. Not like the value attached to early childhood development programs by Nurture with Care Kids Academy which is well known for its innovation, some <a href="https://nurturewithcarekidsacademy.com/">daycares in Oak Harbor WA</a> may forget this part and do not give the <a href="https://nurturewithcarekidsacademy.com/programs/toddlers-in-oak-harbor">best toddler daycare</a>.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>3. How do preschool programs account for all children in an emergency?</Accordion.Header>
                                            <Accordion.Body>
                                                There are emergency procedures ready to ensure all children are safe at Nurture with Care Kids Academy. These procedures at our preschool programs near Oak Harbor include evacuation drills or even head count protocols.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>4. How to apply for the preschool program?</Accordion.Header>
                                            <Accordion.Body>
                                                To enroll in our preschool learning center, visit our website or contact us directly. You will need to fill out and submit the online application form and any other necessary papers. From that point, we will guide you through everything while you ask questions.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>5. What is the duration of the preschool program?</Accordion.Header>
                                            <Accordion.Body>
                                                The duration of our preschools in Oak Harbor WA, is from 9 am to 4 pm. This time frame allows for a complete curriculum in varied subjects and activities that promote intellectual development, social skills, and emotional well-being.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>6. What are some tips for preparing your child for preschool?</Accordion.Header>
                                            <Accordion.Body>
                                                Some things you can do to prepare your child for early learning preschool include having routines, reading books on school-going, and encouraging independence in simple tasks like dressing or eating. Just before the child begins attending preschool, invite friends over so that they may become familiar with strangers.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>7. What are some common concerns that parents have about preschool?</Accordion.Header>
                                            <Accordion.Body>
                                                Parents' common issues about Oak Harbor preschool education include separation anxiety, academic preparation, safety, and socialization. They may be concerned whether their child will fit in the new setting and mingle with his mates as expected to develop himself. The concern for separation anxiety, academic readiness, safety, and socialization among parents who take their children to Oak Harbor preschool education is addressed by Nurture with Care Kids Academy through open communication that eases their worry about their child’s adjustment and development.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header>8. What are some signs that your child has difficulty adjusting to preschool?</Accordion.Header>
                                            <Accordion.Body>
                                                Some signals that your kid might have problems adjusting to preschool consist of being overly possessive at drop-off time, withdrawing from activities or companions while the <a href="https://nurturewithcarekidsacademy.com/programs/young-toddlers-in-oak-harbor">early childhood development programs</a>, excessive crying or tantrums, physical symptoms such as headaches or stomach aches, and developmental regression signs. Early intervention is possible by speaking to teachers and observing your children's behavior.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </section>
                </Layout>
            </div>
        </>
    )
}

export default Preschool